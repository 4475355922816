import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Code from '../components/code';
export const _frontmatter = {
  "title": "Warm Coding Hands",
  "date": "2020-01-17",
  "promo": "flexbox",
  "description": "How to keep them money makers warm while you work",
  "color": "#ee385e"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* prettier-ignore */
    }
    {
      /*
       PAIN: 
       SOLUTION: sous vide
       LINKS:
         - solve it once
         - /post/favorite-gear/
         - /post/learn-to-draw/
        - things I've tried
       - sous vide
       */
    }
    <p>{`My hands and fingers get freezing cold during the winter months. Even in a warm office, even when wearing a hoodie. Cold hands makes writing code difficult - your fingers get stiff, typing slows down, and you have a constant distraction while you're trying to work.`}</p>
    <p>{`In the spirit of `}<a parentName="p" {...{
        "href": "/post/solve-it-once/"
      }}>{`solve it once`}</a>{`, I set out to find a solution to this annoying problem.`}</p>
    <h2>{`What didn't work`}</h2>
    <h3>{`gloves`}</h3>
    <p>{`I tried the obvious solution first: gloves! After trying five pairs I came to the conclusion that you can't type (fast) with gloves, no matter what their marketing sites say. The fingerless kind were the closest, but then you still got ten cold digits.`}</p>
    <h3>{`disposable warmers`}</h3>
    <p>{`These things are pretty much only good if they're inside of a glove or sock. Otherwise they're too weak and ineffective.`}</p>
    <h3>{`electric warmers`}</h3>
    <p>{`I tried rechargeable electric warmers like this lulu bob thingy.`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/img/lulu-bob.jpeg",
        "alt": "electric hand warmer"
      }}></img><br parentName="p"></br>{`
`}{`As well as a few other contraptions that had good reviews on Amazon. The problem with all of them is they only heat the small inside part of your hand, while the outside and your fingers still freeze. Also the heat it gives you doesn't last so as soon as you let go and start to type, the cold returns fast.`}</p>
    <h3>{`electric heating pads`}</h3>
    <p>{`I rigged up a heating pad like a hot pocket around my keyboard & mouse. But the blasted thing kept giving me small electrocutions. It also made my mouse too hard to use.`}</p>
    <h3>{`small desk heaters`}</h3>
    <p>{`These work OK but it's hard to aim them in a way that gets your whole hands. Also the heat is pushed by air and it can make your hands feel even colder if you're not positioned close enough. Overall they're a hassle. I tripped a few breakers at Domo giving them a shot though :)`}</p>
    <h3>{`corn bags`}</h3>
    <p>{`I really like these `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B01C3GAI78/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=geddski-20&creative=9325&linkCode=as2&creativeASIN=B01C3GAI78&linkId=fa80483a36e04875ae558f4733ff593b"
      }}>{`microwavable corn bags`}</a>{`. They keep their heat for a good while and release a bit of moisture which seems to helps the heat penetrate deeper. I used this for a long time but got tired of having to microwave it every hour and a half or so. I still use it for cold feet every now and then.`}</p>
    <h2>{`The Discovery`}</h2>
    <p>{`One day I was lobstering up in my hot tub (don't judge me, I've wanted a hot tub since I was a kid) thinking about the cold hands problem. I pondered how effective water is at heating the body. I did some research as to why that is. Here's a good `}<a parentName="p" {...{
        "href": "http://spot.pcc.edu/~lkidoguc/Aquatics/AqEx/Water_Temp.htm"
      }}>{`source`}</a>{` if you're interested. `}</p>
    <p>{`There are many factors but the main ones are:`}</p>
    <ul>
      <li parentName="ul">{`water is about 30 times more conductive to heat than air is`}</li>
      <li parentName="ul"><em parentName="li">{`moving`}</em>{` water like that in hot tubs also has a `}<em parentName="li">{`convection`}</em>{` effect, where the water immediately around you — that gets cooled as it heats you — gets moved away and replaced by new hot water`}</li>
      <li parentName="ul">{`your body sweats but the sweat doesn't evaporate (a cooling effect) when you're submerged in water`}</li>
    </ul>
    <p>{`Ok so a water-based solution is what I wanted. Maybe I could fill up some ziploc bags with hot water?`}</p>
    <p>{`I tried that and it kind of worked but was a bit sketch and very time consuming. I was also worried about damaging `}<a parentName="p" {...{
        "href": "/post/favorite-gear/"
      }}>{`my gear`}</a>{`. Then I remembered the sous vide machine I saw at my Ma and Pa's house. It's a little device meant for cooking meals using hot water.`}</p>
    <p>{`I snagged `}<a parentName="p" {...{
        "href": "https://www.amazon.com/gp/product/B07R7KCXZZ/ref=as_li_tl?ie=UTF8&tag=geddski-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B07R7KCXZZ&linkId=5ddd3a0c6fa247b2caf61c7a79c24ab6"
      }}>{`this one`}</a>{`, plugged it in under my desk, set it to 106 fahrenheit and gave my cold hands a plunge.`}</p>
    <p>{`I'd found it. I'd found `}<em parentName="p">{`the`}</em>{` solution.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/sous-vide.jpg",
        "alt": "sous vide machine for hands"
      }}></img></p>
    <p>{`This thing works amazingly well. It's like a mini hot tub just for your hands! You can set it to your exact perfect temperature and it holds it there all day. I've dialed mine in to 110 fahrenheit and I love using it. `}</p>
    <p>{`A good rhythm I've found is to work one `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Pomodoro_Technique"
      }}>{`pomodoro`}</a>{` session (30 minutes for me) then soak the hands for about a minute. My hands heat deeply — to the bone it feels like — and stay warm all through the next coding session.`}</p>
    <p>{`Some tips if you try this out:`}</p>
    <ul>
      <li parentName="ul">{`start with a lower temperature like 104 fahrenheit and see what you can get used to`}</li>
      <li parentName="ul">{`don't overdo it or burn yourself or anything silly, I'm not responsible for anything :)`}</li>
      <li parentName="ul">{`your hands might get a little dry from all the water exposure (science is weird). I use a bit of `}<a parentName="li" {...{
          "href": "https://www.amazon.com/gp/product/B0006O4M8Q/ref=as_li_tl?ie=UTF8&tag=geddski-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B0006O4M8Q&linkId=a535dad30c946f33c463b05511c2872f"
        }}>{`hand healer`}</a>{` at night anyway and that keeps 'em from getting dry and cracking`}</li>
      <li parentName="ul">{`put fresh water in it every week`}</li>
    </ul>
    <p>{`People who work remote will probably love this. I would have used it at my open office jobs too had I thought of it back then. But if it's too awkward for you just go with the corn bag approach, that's a close second.`}</p>
    <p>{`Now you know how to keep them money makers nice 'n toasty. Happy coding!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      